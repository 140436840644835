import { graphql } from 'gatsby'
import React from 'react'
import Banner from 'components/Banner'
import Breadcrumb from 'components/Breadcrumb'
import Body from 'components/Body'
import PostList from 'components/PostList'
import Footer from 'components/Footer'
import { tr } from 'js/translations.js'
import { useLocalization } from 'gatsby-theme-i18n'
import SEO from 'components/SEO'

const Page = ({ data }) => {
  const { locale } = useLocalization()
  const page = data[`page_${locale}`]
  const resources = data[`resources_${locale}`].nodes
  const teachers = resources.filter((resource) =>
    resource.resourceType.includes('Teacher')
  )
  const videos = resources.filter((resource) =>
    resource.resourceType.includes('Video')
  )
  const articles = resources.filter((resource) =>
    resource.resourceType.includes('Article')
  )

  const breadcrumb = [
    {
      text: tr('HOME', locale),
      link: '/',
    },
    {
      text: page.title,
    },
  ]

  const titleContent = {
    en: {
      teacher: {
        title: 'Find a Practitioner',
        excerpt:
          'Only the qualified teachers listed below are entitled or authorized to use the recognised logo of the International Feldenkrais Federation (IFF) in Mainland China, Hong Kong & Macau.',
        addLogo: true,
      },
      video: {
        title: 'Videos for Streaming',
      },
      article: {
        title: 'Download Articles on Feldenkrais',
      },
    },
    zh: {
      teacher: {
        title: '找到执业者',
        excerpt:
          '只有以下所列的合格老师才有权或被授权在中国大陆、香港和澳门使用国际菲登奎斯联合会(IFF)认可的标识。',
      },
      video: {
        title: '流媒体视频',
      },
      article: {
        title: '下载关于菲登奎斯的文章',
      },
    },
  }

  return (
    <div className='page'>
      <div>
        <SEO page={page} />
        <Banner content={page.banner} />
        <Breadcrumb items={breadcrumb} />
        <Body body={page.body} />
        <PostList
          titleContent={titleContent[locale].teacher}
          content={teachers}
          category='teacher'
          showListBg={true}
          showButtonLinks={true}
        />
        <PostList
          titleContent={titleContent[locale].video}
          content={videos}
          category='video'
          showListBg={true}
          showButtonLinks={true}
        />
        <PostList
          titleContent={titleContent[locale].article}
          content={articles}
          category='article'
          showListBg={true}
          showButtonLinks={true}
        />
        <Footer />
      </div>
    </div>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    page_en: contentfulPage(
      slug: { eq: "resources" }
      node_locale: { eq: "en-US" }
    ) {
      id
      title
      seo {
        title
        description
      }
      banner {
        pretitle
        title
        subtitle
      }
      body {
        ... on ContentfulBlockIntro {
          __typename
          id
          image {
            ...GatsbyImage
          }
          caption
          title
          body {
            raw
          }
        }
        ... on ContentfulBlockIntroExtended {
          __typename
          imageText1 {
            image {
              ...GatsbyImage
            }
            title
            body {
              raw
            }
            link
          }
          imageText2 {
            image {
              ...GatsbyImage
            }
            title
            body {
              raw
            }
            link
          }
          image {
            image {
              ...GatsbyImage
            }
            caption
          }
        }
        ... on ContentfulBlockCenterText {
          __typename
          id
          title
          body {
            raw
          }
          isLeftAligned
        }
        ... on ContentfulBlockImageText {
          __typename
          id
          image {
            ...GatsbyImage
          }
          caption
          title
          body {
            raw
          }
        }
        ... on ContentfulBlockContact {
          __typename
          phone
          email
          address {
            raw
          }
          googleMapLink
          baiduMapLink
          image {
            ...GatsbyImage
          }
        }
      }
    }
    page_zh: contentfulPage(
      slug: { eq: "resources" }
      node_locale: { eq: "zh-CN" }
    ) {
      id
      title
      seo {
        title
        description
      }
      banner {
        pretitle
        title
        subtitle
      }
      body {
        ... on ContentfulBlockIntro {
          __typename
          id
          image {
            ...GatsbyImage
          }
          caption
          title
          body {
            raw
          }
        }
        ... on ContentfulBlockIntroExtended {
          __typename
          imageText1 {
            image {
              ...GatsbyImage
            }
            title
            body {
              raw
            }
            link
          }
          imageText2 {
            image {
              ...GatsbyImage
            }
            title
            body {
              raw
            }
            link
          }
          image {
            image {
              ...GatsbyImage
            }
            caption
          }
        }
        ... on ContentfulBlockCenterText {
          __typename
          id
          title
          body {
            raw
          }
          isLeftAligned
        }
        ... on ContentfulBlockImageText {
          __typename
          id
          image {
            ...GatsbyImage
          }
          caption
          title
          body {
            raw
          }
        }
        ... on ContentfulBlockContact {
          __typename
          phone
          email
          address {
            raw
          }
          googleMapLink
          baiduMapLink
          image {
            ...GatsbyImage
          }
        }
      }
    }
    resources_en: allContentfulResource(
      filter: { node_locale: { eq: "en-US" } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        slug
        title
        excerpt
        isFeatured
        imageThumb {
          ...GatsbyImage
        }
        resourceType
        externalLink
      }
    }
    resources_zh: allContentfulResource(
      filter: { node_locale: { eq: "zh-CN" } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        slug
        title
        excerpt
        isFeatured
        imageThumb {
          ...GatsbyImage
        }
        resourceType
        externalLink
      }
    }
  }
`
